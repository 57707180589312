import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";

// import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAWycKkmKDo_Zr7OztKmrnb-bfTMw_RlRU",
  authDomain: "shiseido-9fb28.firebaseapp.com",
  databaseURL:
    "https://shiseido-9fb28-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shiseido-9fb28",
  storageBucket: "shiseido-9fb28.appspot.com",
  messagingSenderId: "370505188950",
  appId: "1:370505188950:web:549eb76c2a364f29179df7",
  measurementId: "G-BYMSJCWN2J",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const rtdb = firebase.database();
export const auth = firebase.auth();

// export const storage = firebase.storage();
