<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column">
          <p class="stream-text">{{ $t("stream.text") }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <iframe
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            v-if="lang == 'de'"
            v-bind:src="messages.urlDe"
            height="100%"
            width="100%"
            style="min-height: 560px"
          ></iframe>
          <iframe
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            v-if="lang == 'fr'"
            v-bind:src="messages.urlFr"
            height="100%"
            width="100%"
            style="min-height: 560px"
          ></iframe>
        </div>
      </div>
    </div>
    <div v-if="lang == 'de' && messages.onlineDe" class="messages">
      <div class="columns">
        <div class="column text">
          <h1 class="title">{{ messages.titleDe }}</h1>
          <div>{{ messages.textDe }}</div>
          <br />
          <span class="cursor" @click="setLanguage('de')">DE</span
          ><span class="default-cursor"> | </span>
          <span class="cursor" @click="setLanguage('fr')">FR</span>
        </div>
      </div>
    </div>
    <div v-if="lang == 'fr' && messages.onlineFr" class="messages">
      <div class="columns">
        <div class="column text">
          <h1 class="title">{{ messages.titleFr }}</h1>
          <div>{{ messages.textFr }}</div>
          <br />
          <span class="cursor" @click="setLanguage('de')">DE</span
          ><span class="default-cursor"> | </span>
          <span class="cursor" @click="setLanguage('fr')">FR</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import { rtdb } from "../firebase";
import { auth } from "../firebase";
// import firebase from "firebase/app";

export default {
  data: function () {
    return {
      lang: localStorage.getItem("lang"),
      messages: [],
    };
  },
  firestore: {
    messages: db.collection("settings").doc("message"),
  },
  methods: {
    setLanguage(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
  },
  created() {
    var uid = auth.currentUser.uid;
    var user = auth.currentUser.email;
    var userStatusDatabaseRef = rtdb.ref("/status/" + uid);
    var isOfflineForDatabase = {
      user: user,
      state: "offline",
      // last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOnlineForDatabase = {
      user: user,
      state: "online",
      // last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    rtdb.ref(".info/connected").on("value", function (snapshot) {
      if (snapshot.val() == false) {
        return;
      }
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
  },
  beforeDestroy() {
    rtdb
      .ref("/status/" + auth.currentUser.uid)
      .set({ user: auth.currentUser.email, state: "offline" });
  },
};
</script>
<style scoped>
.stream-text {
  text-align: center;
}
iframe {
  width: 100%;
}
.messages {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255);
  text-align: center;
  margin: 0 auto;
}
.text {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
