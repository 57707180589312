<template>
  <div class="container">
    <h1 class="title shiseido-title">Backend</h1>

    <div class="columns">
      <div class="column">
        <label class="label">Vimeo URL German</label>
        <div class="columns">
          <div class="column">
            <input
              v-model="messages.urlDe"
              placeholder="http://www.xyz.com"
              type="text"
              class="input"
            />
          </div>
          <div class="column">
            <button
              class="button is-primary"
              :class="{ 'is-loading': loadingUrlMessage.urlDe }"
              @click="updateUrl('urlDe')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <label class="label">Vimeo URL French</label>
        <div class="columns">
          <div class="column">
            <input
              v-model="messages.urlFr"
              placeholder="http://www.xyz.com"
              type="text"
              class="input"
            />
          </div>
          <div class="column">
            <button
              class="button is-primary"
              :class="{ 'is-loading': loadingUrlMessage.urlFr }"
              @click="updateUrl('urlFr')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column">
        <label class="label">Screen Notification</label>

        <div class="columns">
          <div class="column is-half">
            <div>German</div>
            <input
              v-model="messages.titleDe"
              class="input"
              type="text"
              placeholder="Titel"
            />
          </div>
          <div class="column is-half">
            <div>French</div>
            <input
              v-model="messages.titleFr"
              class="input"
              type="text"
              placeholder="Titre"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <textarea
              v-model="messages.textDe"
              class="textarea"
              placeholder="Nachricht"
            ></textarea>
          </div>
          <div class="column is-half">
            <textarea
              v-model="messages.textFr"
              class="textarea"
              placeholder="Message"
            ></textarea>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <button
              class="button is-primary"
              :class="{
                'is-loading': loadingMessage,
              }"
              @click="updateMessages()"
            >
              Save Texts
            </button>
          </div>
          <div class="column is-half">
            <div class="columns">
              <div class="column">
                <label class="switch">
                  <input
                    @input="switchMessageDe(!messages.onlineDe)"
                    type="checkbox"
                    v-model="messages.onlineDe"
                  />
                  <span class="slider"></span>
                </label>
                <span class="switch-text"
                  >Screen Notification DE:
                  {{ messages.onlineDe ? "Showing Now" : "Hidden" }}</span
                ><br /><br />
                <label class="switch">
                  <input
                    @input="switchMessageFr(!messages.onlineFr)"
                    type="checkbox"
                    v-model="messages.onlineFr"
                  />
                  <span class="slider"></span>
                </label>
                <span class="switch-text"
                  >Screen Notification FR:
                  {{ messages.onlineFr ? "Showing Now" : "Hidden" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="columns">
      <div class="column">
        <label class="label"
          >Users on Stream Page
          <span class="showed-up">(Showed Up: {{ status.length }})</span></label
        >
      </div>
    </div>
    <div v-if="userFormatting" class="columns is-multiline">
      <div
        class="column is-narrow users"
        v-for="user in status"
        :key="user.user"
        :class="user.state"
      >
        {{ user.user }}
      </div>
    </div>
    <div v-if="!userFormatting" class="usersSimple">
      <div
        class=""
        v-for="user in status"
        :key="user.user"
        :class="user.state == 'online' ? 'onlineSimple' : ''"
      >
        {{ user.user }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <button
          class="button is-primary"
          @click="userFormatting = !userFormatting"
        >
          Change Formatting</button
        ><br /><br />
        <button
          class="button is-primary"
          :class="{ 'is-loading': loadingUrlMessage.urlDe }"
          @click="resetStatus"
        >
          Reset List
        </button>
      </div>
    </div>

    <p class="has-text-danger" v-if="errorMessage">{{ errorMessage }}</p>
    <hr v-on:click.alt="showUserImport = true" />
    <div v-if="showUserImport">
      <div class="columns">
        <div class="column">
          <label class="label">User import</label>

          <textarea
            v-model="userImport"
            rows="10"
            class="textarea"
            placeholder="Text im JSON Format einfügen.

                [{
                'email': 'email1@gmail.com',
                'password': 'testPW3'
                },{
                'email': 'email2@gmail.com',
                'password': 'testPW2'
                }]
                
Uploaderfolgsbericht in der Browser-Konsole."
          ></textarea>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button
            class="button is-primary"
            :class="{ 'is-loading': loadingUser, 'is-static': !userImport }"
            @click="uploadUsers()"
          >
            Upload User List
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import { auth } from "../firebase";
import { rtdb } from "../firebase";

export default {
  data() {
    return {
      urlDe: "",
      urlFr: "",
      loadingUrlMessage: {
        urlDe: false,
        urlFr: false,
      },
      loadingUser: false,
      loadingMessage: false,
      errorMessage: "",
      onlineDe: false,
      onlineFr: false,
      messages: [],
      status: [],
      showUserImport: false,
      userImport: "",
      userFormatting: true,
    };
  },
  firebase: {
    status: rtdb.ref("status"),
  },
  firestore: {
    messages: db.collection("settings").doc("message"),
  },
  methods: {
    resetStatus() {
      rtdb.ref("status").remove();
    },
    updateUrl(lang) {
      this.loadingUrlMessage[lang] = true;
      const payload = {
        [lang]: this.messages[lang],
      };
      db.collection("settings")
        .doc("message")
        .update(payload)
        .then(() => {
          this.loadingUrlMessage[lang] = false;
        })
        .catch((error) => {
          this.loadingUrlMessage[lang] = false;
          this.errorMessage = error;
        });
    },
    updateMessages() {
      this.loadingMessage = true;
      const payload = {
        titleDe: this.messages.titleDe,
        titleFr: this.messages.titleFr,
        textDe: this.messages.textDe,
        textFr: this.messages.textFr,
      };
      db.collection("settings")
        .doc("message")
        .update(payload)
        .then(() => {
          this.loadingMessage = false;
        })
        .catch((error) => {
          this.loadingMessage = false;
          this.errorMessage = error;
        });
    },
    switchMessageDe(payload) {
      db.collection("settings")
        .doc("message")
        .update({ onlineDe: payload })
        .then(() => {})
        .catch((error) => {
          this.onlineDe = !this.onlineDe;
          this.errorMessage = error;
        });
    },
    switchMessageFr(payload) {
      db.collection("settings")
        .doc("message")
        .update({ onlineFr: payload })
        .then(() => {})
        .catch((error) => {
          this.onlineFr = !this.onlineFr;
          this.errorMessage = error;
        });
    },
    uploadUsers() {
      const users = JSON.parse(this.userImport);
      for (var i = 0; i < users.length; i++) {
        auth
          .createUserWithEmailAndPassword(users[i].email, users[i].password)
          .then((success) => {
            console.log("Upload Success: ", success);
          })
          .catch((error) => {
            console.log("Upload Failure: ", error);
          });
      }
    },
  },
};
</script>

<style>
.switch-text {
  font-weight: bold;
  margin-left: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f20000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f20000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.users {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid #e9e9e9;
}
.online {
  border-color: rgb(125, 215, 0);
}

.usersSimple {
  margin-bottom: 20px;
}
.onlineSimple {
  color: rgb(125, 215, 0);
}

.showed-up {
  font-weight: 300;
}
</style>
