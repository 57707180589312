<template>

    <div>

        <div id="bg-login"></div>

        <div class="container">

            <h1 class="title shiseido-title">{{ $t('conditions.title') }}</h1>
            <p v-html="$t('conditions.text')"></p>
            <br>
            <label class="checkbox">
            <input type="checkbox" v-model="checked">
                <b> {{ $t('conditions.accept') }}</b>
            </label>
            <br><br>
            <button
            class="button is-primary"
            :class="{ 'is-static' : !checked}"
            @click="next"
            >{{ $t('conditions.continue') }}</button>
        </div>
  </div>

</template>

<script>


export default {
    data() {
        return {
            checked: false
        }
    },
    methods: {
        next() {
            if (this.checked) {
                localStorage.setItem('termAccepted', true);
                this.$router.push({ path: 'stream' })
            } else {
                this.checked = false;
            }
        }
    }
}
</script>

<style scoped>
    ol {
        margin-left: 1em;
    }
    input[type="checkbox"]{
        width: 20px; 
        height: 20px;
        cursor: pointer;
    }
</style>