<template>
  <div>
    <h1 class="title shiseido-title">{{ $t("login.title") }}</h1>
    <p class="shiseido-text">{{ $t("login.text") }}</p>

    <div class="columns">
      <div class="column is-one-third">
        <form>
          <input
            v-model="email"
            :placeholder="$t('login.email')"
            type="email"
            class="input"
          />

          <div class="input-gap">
            <input
              v-model="password"
              type="password"
              :placeholder="$t('login.password')"
              class="input"
            />
          </div>

          <button
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            @click="signIn($event)"
          >
            {{ $t("login.continue") }}
          </button>
        </form>
      </div>
    </div>

    <p class="has-text-danger" v-if="errorMessage">{{ errorMessage }}</p>

    <h1 class="title shiseido-title">{{ $t("login.quiz-title") }}</h1>
    <p>{{ $t("login.quiz-text") }}</p>
    <br />
    <button
      class="button is-primary"
      :class="{ 'is-loading': loading }"
      @click="goTo('https://b.socrative.com/login/student/')"
    >
      {{ $t("login.quiz-button") }}
    </button>

    <h1 class="title shiseido-title">{{ $t("login.support") }}</h1>
    <p>{{ $t("login.support-text") }}</p>
  </div>
</template>

<script>
import { auth } from "../firebase";

export default {
  data() {
    return {
      auth,
      email: "",
      password: "",
      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    async signIn(event) {
      event.preventDefault();
      this.loading = true;
      this.errorMessage = "";

      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);
        this.$router.push("/stream");
      } catch (error) {
        console.log("error", error.code);
        console.log();
        this.errorMessage = this.$t("error." + error.code);
      }

      this.loading = false;
    },
    goTo(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.shiseido-title {
  margin-top: 30px;
}
.input-gap {
  margin-top: 15px;
  margin-bottom: 15px;
}
.shiseido-text {
  margin-bottom: 10px;
}
</style>
