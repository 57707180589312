<template>
  <div id="app">
    <header>
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <a href="/"
              ><img v-on:click.alt="backend" id="logo" src="./assets/logo.png"
            /></a>
          </div>
          <div class="column has-text-right">
            <span v-if="auth.currentUser" @click="logout()" class="cursor"
              >Logout <span class="default-cursor"> | </span></span
            >
            <span class="cursor" @click="setLanguage('de')">DE</span
            ><span class="default-cursor"> | </span>
            <span class="cursor" @click="setLanguage('fr')">FR</span>
          </div>
        </div>
      </div>
    </header>

    <router-view></router-view>
  </div>
</template>

<script>
import { auth } from "./firebase";

export default {
  name: "App",
  data: function () {
    return {
      auth,
    };
  },
  components: {},
  methods: {
    setLanguage(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    logout() {
      this.$router.push("/logout");
    },
    backend() {
      this.$router.push("/backend");
    },
  },
  created() {
    document.title = "Shiseido";
  },
};
</script>

<style>
#logo {
  width: 140px;
  margin: 15px 0;
}
.default-cursor {
  cursor: default;
  color: #aaaaaa;
}
</style>
