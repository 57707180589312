import Vue from "vue";
import App from "./App.vue";
import { firestorePlugin } from "vuefire";
import { rtdbPlugin } from "vuefire";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";

// Firebase
import VueCompositionApi from "@vue/composition-api";
import { auth } from "./firebase";

Vue.use(VueCompositionApi);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(firestorePlugin);
Vue.use(rtdbPlugin);
Vue.config.productionTip = false;

// CSS Bulma
require("./assets/main.scss");

// Router
import Home from "./components/Home";
import Conditions from "./components/Conditions";
import Stream from "./components/Stream";
import Backend from "./components/Backend";
import Logout from "./components/Logout";

const router = new VueRouter({
  routes: [
    { path: "/", name: "home", component: Home },
    {
      path: "/conditions",
      name: "conditions",
      component: Conditions,
      meta: { requiresAuth: true },
    },
    {
      path: "/stream",
      name: "stream",
      component: Stream,
      meta: { requiresAuth: true },
    },
    {
      path: "/backend",
      name: "backend",
      component: Backend,
      meta: { requiresAuth: true },
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: { requiresAuth: true },
    },
    {
      path: "*",
      component: Home,
    },
  ],
});

// Router Guard
router.beforeResolve((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;
  if (isAuthenticated && to.path == "/") {
    next("/stream");
  }

  if (isAuthenticated && to.path == "/backend") {
    if (
      isAuthenticated.email === "charlotte.guezais@emea.shiseido.com" ||
      isAuthenticated.email === "beat.schnydrig@framecom.ch" ||
      isAuthenticated.email === "brauchli@gmail.com"
    ) {
      next();
    } else {
      next("/");
    }
  }

  // if (isAuthenticated && to.path == "/stream") {
  //   if (localStorage.termAccepted) {
  //     next();
  //   } else {
  //     next("/conditions");
  //   }
  // }

  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

// Languages
const navLang = navigator.language.slice(0, 2);
var langCode = null;
if (!localStorage.getItem("lang")) {
  langCode = ["de", "fr"].includes(navLang) ? navLang : "de";
  localStorage.setItem("lang", langCode);
}

var language = localStorage.getItem("lang") || langCode;
var selection = require("./languages/lang");

const i18n = new VueI18n({
  locale: language,
  messages: selection,
});

export default router;

const unsubscribe = auth.onAuthStateChanged(() => {
  new Vue({
    i18n,
    router,
    render: (h) => h(App),
  }).$mount("#app");

  unsubscribe();
});
