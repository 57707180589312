<template>
  <div></div>
</template>

<script>
import { auth } from "../firebase";

export default {
  created() {
    auth.signOut().then(() => {
      this.$router.push("/");
    });
  },
};
</script>
